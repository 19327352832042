<template>
  <div class="input-data">
    <h2 class="is-size-5 has-text-weight-bold has-text-dark mb-4">{{ $t('warehouse.probiotics') }}/{{ $t('warehouse.chemical') }}</h2>

    <input-probiotics-bulk/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "InputProbioticsForm",
  components: {
    InputProbioticsBulk: () => import("@/components/Farm/UseItem/InputProbioticsBulk"),
  },
  computed: {
    ...mapGetters('farm', [
        'selectedFarm',
        'isPro',
    ]),
    embed () {
      return this.$route.query.output === 'embed'
    },
  },
}
</script>
